import "../styles/global.css"
import React from "react"

import { Router } from "@reach/router"
import InvoicePage from "./invoice"
import NewPage from "./new"

const HomeRoute = props => {
  return <p>No log exists here.</p>
}

const IndexPage = () => {
  return (
    <Router>
      <HomeRoute path="/" />
      <InvoicePage path="/invoice/:invoiceId" />
      <NewPage path="/new" />
    </Router>
  )
}

export default IndexPage
